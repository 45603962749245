<template>
  <v-container
    id="trains"
    fluid
    tag="section"
    class="container-full-size"
  >
    <v-data-table
      :headers="headers"
      :items="trains"
      :page="currentPage"
      :search="search"
      :no-data-text="str['no_data']"
      :no-results-text="str['no_data']"
      class="elevation-1"
      @click:row="openTrain"
      @update:page="changePage"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="str['search']"
            single-line
            hide-details
          />
          <v-spacer />
          <v-btn
            v-if="editPermission"
            color="success"
            dark
            class="mb-2"
            @click="newTrain()"
          >
            {{ str['add'] }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          color="teal lighten-2"
          @click.stop="duplicateItem(item)"
        >
          mdi-content-duplicate
        </v-icon>
        <v-icon
          small
          class="mr-0"
          color="error"
          @click.stop="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>

    <v-dialog
      v-if="item"
      v-model="dialogItem"
      persistent
      max-width="850px"
    >
      <train
        :data="item"
        :saveCallback="trainSaved"
        :closeCallback="closeTrain"
      />
    </v-dialog>
  </v-container>
</template>

<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'

  export default {
    components: {
      Train: () => import('@/views/dashboard/components/trainingPlan/Train'),
    },
    data () {
      const user = Utils.getUser()
      const editPermission = Utils.hasPermission('base_trng_plans_edit')
      const headers = [
        { text: window.strings['id'], value: 'id', width: 60 },
        { text: window.strings['name'], value: 'name', align: 'center' },
      ]
      if (editPermission) {
        headers.push({ text: window.strings['actions'], value: 'actions', sortable: false, align: 'center', width: 80 })
      }
      return {
        str: window.strings,
        user: user,
        trains: [],
        currentPage: 1,
        search: '',
        editPermission: editPermission,
        headers: headers,
        dialogItem: false,
        item: null,
      }
    },
    watch: {
      dialogItem (val) {
        const dialog = document.getElementsByClassName('v-dialog')
        if (dialog && dialog[0]) {
          dialog[0].scrollTo(0, 0)
        }
        val || this.closeTrain()
      },
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }
      this.$isLoading(true)
      this.getTrains()
    },
    methods: {
      getTrains: function () {
        const self = this
        Api.getTrains({
          fast: true
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.trains = response.data
            self.checkTableCache()
          } else {
            self.trains = []
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      checkTableCache: function () {
        if (window.cacheTables && window.cacheTables.trainsBase && window.cacheTables.trainsBase.page) {
          this.currentPage = window.cacheTables.trainsBase.page
          delete window.cacheTables.trainsBase
        }
      },
      changePage: function (page) {
        this.currentPage = page
      },
      deleteItem (item) {
        const self = this
        const id = item.id
        this.$confirm(
          window.strings['want_delete'] + (item ? (' "' + item.name + '"') : '') + '?',
          '',
          'warning',
          Utils.getAlertOptions(true, true)
        ).then(() => {
          self.$isLoading(true)
          Api.deleteTrain({
            id: id,
            client_id: null,
          }, function (response) {
            self.$isLoading(false)
            if (response.success) {
              for (let i = 0; i < self.trains.length; i++) {
                if (self.trains[i].id === id) {
                  self.trains.splice(i, 1)
                  break
                }
              }
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }).catch(() => { })
      },
      duplicateItem (item) {
        const self = this
        this.$confirm(
          window.strings['want_duplicate'] + (item ? (' "' + item.name + '"') : '') + '?',
          '',
          'info',
          Utils.getAlertOptions(true, false)
        ).then(() => {
          self.getTrain(item, function (src) {
            Api.newTrain(src, function (response) {
              if (response.success) {
                self.getTrains()
              } else {
                self.$isLoading(false)
                self.$alert(
                  response.message,
                  '',
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          })
        }).catch(() => { })
      },
      getTrain (train, callback) {
        const self = this
        this.$isLoading(true)
        Api.getTrain({
          id: train.id
        }, function (response) {
          if (response.success) {
            callback(response.data[0])
          } else {
            self.$isLoading(false)
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      openTrain: function (item) {
        const self = this
        this.getTrain(item, function (src) {
          self.$isLoading(false)
          self.item = src
          self.dialogItem = true
        })
      },
      newTrain: function () {
        this.item = {
          name: '',
          steps: [],
        }
        this.dialogItem = true
      },
      closeTrain: function () {
        this.item = null
        this.dialogItem = false
      },
      trainSaved: function () {
        this.dialogItem = false
        this.item = null
        this.getTrains()
      },
    },
  }
</script>
